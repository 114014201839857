import React, {useState} from 'react';
import './App.css';
import {Link, Slider} from "@material-ui/core";

function App() {
    const [showResult, setShowResult] = useState(false);

    function handleChange() {
        setShowResult(true);
    }

    return (
        <div className="App">
            <header className="App-header">
                <div style={{maxWidth: '1100px', margin: '2rem auto 0'}}>
                    Bewege einen Slider um die Links zu sehen
                </div>
            </header>
            <body>
            <div style={{maxWidth: '1100px', margin: '5rem auto 0'}}>
                <Slider
                    defaultValue={0}
                    step={1}
                    min={0}
                    max={5}
                    valueLabelDisplay="auto"
                    onChange={handleChange}
                />
                <Slider
                    defaultValue={0}
                    step={1}
                    min={0}
                    max={5}
                    valueLabelDisplay="auto"
                    onChange={handleChange}
                />
                {showResult &&
                    <>

                        <ul>
                            <li>
                                <Link href="/20220503_Fadenbruecke_Sonnen_Dach.pptx" target="_blank">Direktlink im Browser</Link>
                            </li>
                            <li>
                                <Link href="ms-powerpoint:ofe|u|https://hfrb.nambu.one/20220503_Fadenbruecke_Sonnen_Dach.pptx">Office Handler im Browser</Link>
                            </li>
                        </ul>
                        <hr/>
                        <ul>
                            <li>
                                <Link href="file:///V:\Fachschule\001_Abteilung_BEW\04_LEER" target="_blank">Link zum Lokalen Ordner bei HFRB V:\Fachschule\001_Abteilung_BEW\04_LEER</Link>
                            </li>
                            <li>
                                <Link href="ms-powerpoint:ofe|u|file://V:\Fachschule\001_Abteilung_BEW\04_LEER\Test.pptx" target="_blank">Office Handler bei HFRB zur Test.pptx direkt</Link>
                            </li>
                        </ul>
                        <hr/>
                        <ul>
                            <li>
                                <Link href="file://C:\Users\Dominik\Documents\" target="_blank">Link bei Lukas zum Ordner</Link>
                            </li>
                            <li>
                                <Link href="ms-powerpoint:ofe|u|file://C:\Users\Dominik\Documents\Test.pptx" target="_blank">Office Handler bei Lukas zur PPT direkt</Link>
                            </li>
                        </ul>
                    </>
                }
            </div>
            </body>
        </div>
    );
}

export default App;
